<template>
    <section class="mobile-banner-wrap">
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <div class="col-xl-10 banner-title-wrapper">
                    <h1>Find it, Rent or Buy your dream property</h1>
                </div>
                <div class="search-mobile-wrapper">
                    <div class="search-input-wrapper">
                        <div class="input-icon-search">
                            <i class="fas fa-search"></i>
                        </div>
                        <input type="text" v-model="filterData.keywords" placeholder="Search...">
                    </div>
                    <div>
                        <b-button @click="modalShow = !modalShow" v-b-modal.modal-center class="mobileFilter-btn"><span class="icon-filter"></span></b-button>
                        <b-modal v-model="modalShow" id="modal-center" centered>
                            <div class="display-card">
                                <div class="mobileFilter">
                                    <div class="col-xl-6">
                                        <select v-model="filterData.category" class="searchType form-select mb-3">
                                            <option value="">select Type</option>
                                            <option v-for="(item,i) in categories" :key="i" :value="item.slug">{{ item.title }}</option>
                                        </select>
                                    </div>
                                    <div class="col-xl-6">
                                        <select v-model="filterData.location" class="searchType form-select mb-3">
                                            <option value="">Select Location</option>
                                            <option v-for="(item,i) in locations" :key="i" :value="item.slug">{{ item.name }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <button class="univ-btn w-100" @click="filters()"><b-spinner v-if="btnLoader" small></b-spinner> Apply Filter</button>
                                    </div>
                                </div>
                            </div>
                        </b-modal>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
import Helper from "@/services/helper"
export default {
    name: "mobileBanner",
    data() {
        return {
            modalShow: false,
            btnLoader:false,
            filterData:{
                purpose:"all",
                keywords:"",
                category:"",
                location: "",
            },
            categories: [],
            locations: [],
        }
    },
    mounted() {
        let app = this;
        app.renderData();
    },
    methods: {
        async renderData(){
            let app = this;
            await axios.get(api.getUrl('/general-info')).then(function (response) {
                let responseData  = response.data.data;
                app.categories = responseData.property_category;
                app.locations = responseData.location;
            });
        },
        filters(){
            let app = this;
            let filterUrl = Helper.objToUrlParams(app.filterData);
            app.$router.push({path:`/search?${filterUrl}`}); 
        },
        selectPurpose($value){
            let app = this;
            app.filterData.purpose = $value;
        }
    },
}

</script>