<template>
    <section class="bylocation-wrap" v-if="locationLists.length > 0">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="page-title mb-5">
                        <div>
                            <h2>Properties by Area</h2>
                            <p>Aliquam lacinia diam quis lacus euismod</p>
                        </div>
                        <router-link :to="{ name: 'PropertyListing'}" >See All Properties <i class="fas fa-arrow-up"></i></router-link>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-4 col-6 mb-4" v-for="(item,i) in locationLists" :key="i">
                    <router-link :to="{ path: '/search?location='+item.slug }">
                        <div class="bylocation-block">
                            <div class="thumb-img">
                                <img :src="item.image" >
                            </div>
                            <div>
                                <h3>{{ item.name }}</h3>
                                <p>{{ item.count }} Properties</p>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
export default {
    name: 'ByLocation',
    data() {
      return {
        locationLists:[],
      }
    },
    mounted() {
        let app = this;
        app.renderLocation();
    },
    methods: {
        async renderLocation(){
            let app = this;
            await axios.get(api.getUrl('/locations')).then(function (response) {
                if(response.data.success){
                    app.locationLists = response.data.data;
                }
            });
        }
    },
}
</script>