<template>
    <section class="premiumWrap" v-if="properties.length > 0" >
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="page-title">
                        <div>
                            <h2>{{ sectionTitle }}</h2>
                            <p>{{ sectionDesc }}</p>
                        </div>
                        <router-link :to="{ name: 'AdsPropertyListing',params:{slug:'premium'} }">See All Properties <i class="fas fa-arrow-up"></i></router-link>
                    </div>
                </div>
                <div class="col-12 mt-5">
                    <carousel v-bind="horizontalSlider">
                        <ItemList v-for="(item,i) in properties" :key="i" :item="item" />
                    </carousel>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import carousel from 'vue-owl-carousel'
import axios from 'axios'
import api from '@/services/api'
import ItemList from '@/components/ItemList';
import Helper from "@/services/helper"
export default {
    name: 'PremiumListing',
    components: {  
        carousel,
        ItemList
    },
    data() {
        return {
            horizontalSlider: {
                nav: false,
                dots:false,
                items: 4,
                margin: 15,
                autoplay: false,
                responsive: { 0: { items: 2, nav: false }, 769: { items: 3, nav: false} , 1200: { items: 4, nav: false, } }
            },
            
            sectionTitle:"",
            sectionDesc:"",
            properties:[],
        }
    },
    mounted() {
        let app = this;
        app.renderProperty();
    },
    methods: {
        async renderProperty(){
            let app = this;
            Helper.showSpinner();
            await axios.get(api.getUrl('/ads/properties/premium')).then(function (response) {
                if(response.data.success){
                    app.sectionTitle    = response.data.data.additional.title;
                    app.sectionDesc     = response.data.data.additional.description;
                    app.properties      = response.data.data.lists;
                }
            }).finally(() => {
                Helper.hideSpinner();
            });
        }
    }
}
</script>