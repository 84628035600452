<template>
    <section class="baseInfo-wrap">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="page-title text-center justify-content-center">
                        <div>
                            <h2>See How KJ Real Estate Can Help</h2>
                            <p>Aliquam lacinia diam quis lacus euismod</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 mt-5">
                    <div class="baseInfo-block text-center">
                        <img src="../../../assets/images/icon1.svg" alt="">
                        <h3>Buy a property</h3>
                        <p>Nullam sollicitudin blandit eros eu pretium. Nullam maximus ultricies auctor.</p>
                        <router-link to="search?purpose=sale" class="univ-btn px-4">Buy a Property</router-link>
                    </div>
                </div>
                <div class="col-xl-4 mt-5">
                    <div class="baseInfo-block text-center display-card">
                        <img src="../../../assets/images/icon2.svg" alt="">
                        <h3>Sell a property</h3>
                        <p>Nullam sollicitudin blandit eros eu pretium. Nullam maximus ultricies auctor.</p>
                        <template v-if="isAuthenticated">
                            <router-link :to="{name:'AddUserProperty'}" class="univ-btn px-4" > Sell Property</router-link>
                        </template>
                        <template v-else>
                            <a href="javascript:void(0);" class="univ-btn px-4" @click="loginAction('sell-property-btn')" v-b-modal.authModal>Sell Property</a>
                        </template>
                    </div>
                </div>
                <div class="col-xl-4 mt-5">
                    <div class="baseInfo-block text-center">
                        <img src="../../../assets/images/icon3.svg" alt="">
                        <h3>Rent a property</h3>
                        <p>Nullam sollicitudin blandit eros eu pretium. Nullam maximus ultricies auctor.</p>
                        <router-link to="search?purpose=rent" class="univ-btn px-4">Find a Rent</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import store from "@/store"
export default {
    name:'BaseInfo',
    data(){
        return {
            isAuthenticated: store.getters.authenticated,
        }
    },
}
</script>