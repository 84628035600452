<template>
    <section class="categ-wrap" v-if="categoryLists.length > 0">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="page-title">
                        <h2>Explore Property Types</h2>
                        <router-link :to="{ path: '/properties'}">All Types <i class="fas fa-arrow-up"></i></router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row justify-content-end">
                <div class="col-12">
                    <VueSlickCarousel v-bind="settings" class="py-5">
                        <div class="categ-block" v-for="(item, i) in categoryLists" :key="i">
                            <router-link :to="{ path: '/search?category='+item.slug}">
                                <div class="categ-icon">
                                    <span class="icon-home"></span>
                                </div>
                                <div class="categ-text">
                                    <h3>{{ item.title }}</h3>
                                    <span>{{ item.count }} Properties</span>
                                </div>
                            </router-link>
                        </div>
                    </VueSlickCarousel>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import axios from 'axios'
import api from '@/services/api'
export default {
    name: 'CategoryBlock',
    components: { VueSlickCarousel },
    data() {
      return {
        settings: {
            arrows: false,
            dots: false,
            focusOnSelect: true,
            infinite: false,
            speed: 500,
            slidesToShow: 6,
            touchThreshold: 5,
            responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 4,
              adaptiveHeight: true,
            },
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
        ],
        },
        categoryLists:[],
      }
    },
    mounted() {
        let app = this;
        app.renderCategory();
    },
    methods: {
        async renderCategory(){
            let app = this;
            await axios.get(api.getUrl('/property-category')).then(function (response) {
                if(response.data.success){
                    app.categoryLists = response.data.data;
                }
            });
        }
    }
}
</script>