<template>
    <section class="directAction-wrap">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="directAction-block">
                        <h2>Start Listing, Buying or Renting <br> A Property With KJ Realstate</h2>
                        <p>Talk to our experts or Browse throught more properties.</p>
                        <template v-if="isAuthenticated">
                            <router-link :to="{name:'AddUserProperty'}" class="univ-btn btn-dark px-5 py-3" ><i class="fas fa-plus"></i> Sell Property</router-link>
                        </template>
                        <template v-else>
                            <a href="javascript:void(0);" class="univ-btn btn-dark px-5 py-3" @click="loginAction('sell-property-btn')" v-b-modal.authModal>Get Started</a>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import store from "@/store"
export default {
    name: 'DirectAction',
    data() {
      return {
        isAuthenticated:store.getters.authenticated,
      }
    },
    methods: {
        loginAction(source) {
            store.commit("setLoginSource", source);
        },
    },
    watch:{
        $route (){
            if(store.getters.authenticated){
                this.isAuthenticated = true;
            }else{
                this.isAuthenticated = false;
            }
        }
    } 
}
</script>