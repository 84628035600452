<template>
    <div>
        <div class="masterSearch-categ">
            <ul>
                <li v-if="filterData.purpose =='all'" class="active-categ" @click="selectPurpose('all')" >All</li>
                <li v-else @click="selectPurpose('all')" >All</li>

                <li v-if="filterData.purpose =='sale'" class="active-categ" @click="selectPurpose('sale')">For Buy</li>
                <li v-else @click="selectPurpose('sale')">For Buy</li>

                <li v-if="filterData.purpose =='rent'" class="active-categ" @click="selectPurpose('rent')">For Rent</li>
                <li v-else @click="selectPurpose('rent')">For Rent</li>
            </ul>
        </div>
        <div class="masterSearch py-4 display-card">
            <div class="row align-items-center search-mob">
                <div class="col-xl-4">
                    <input type="text" v-model="filterData.keywords" class="searchInput" placeholder="Enter Keyword">
                </div>
                <div class="col-xl-4">
                    <multiselect
                        placeholder="Select Type"
                        v-model="category"
                        :options="categories"
                        :custom-label="categoryLabel"
                        @select="getSelectedCategory"
                        @remove="removedSelectedCategory"
                    >
                    </multiselect>
                </div>
                <div class="col-xl-3">
                    <multiselect
                        placeholder="Select Location"
                        v-model="location"
                        :options="locations"
                        :custom-label="locationLabel"
                        @select="getSelectedLocation"
                        @remove="removedSelectedLocation"
                    >
                    </multiselect>
                </div>
                <div class="col-xl-1">
                    <div class="searchOption">
                        
                        <div class="searchBtn" @click="filters()">
                            <span class="icon-search"></span>
                        </div>
                    </div>
                </div>
        </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import axios from 'axios'
import api from '@/services/api'
import Helper from "@/services/helper"
export default {
    name: 'MasterSearch',
    components: { Multiselect },
    data () {
      return {
        category:"",
        location: "",
        filterData:{
            purpose:"all",
            keywords:"",
            category:"",
            location: "",
        },
        categories: [],
        locations: [],
      }
    },
    mounted() {
        let app = this;
        app.renderData();
    },
    methods: {
        async renderData(){
            let app = this;
            await axios.get(api.getUrl('/general-info')).then(function (response) {
                let responseData  = response.data.data;
                let categoryRows = [];
                responseData.property_category.forEach(function (item) {
                    categoryRows.push({title:item.title,slug:item.slug});
                });
                app.categories = categoryRows;
                let locationRows = [];
                responseData.location.forEach(function (item) {
                    locationRows.push({name:item.name,slug:item.slug});
                });
                app.locations = locationRows;
            });
        },
        filters(){
            let app = this;
            let filterUrl = Helper.objToUrlParams(app.filterData);
            app.$router.push({path:`/search?${filterUrl}`}); 
        },
        selectPurpose($value){
            let app = this;
            app.filterData.purpose = $value;
        },
        categoryLabel(option) {
            return `${option.title}`;
        },
        getSelectedCategory(option){
            let app = this;
            app.filterData.category = option.slug;
        },
        removedSelectedCategory(){
            let app = this;
            app.filterData.category = "";
        },
        locationLabel(option) {
            return `${option.name}`;
        },
        getSelectedLocation(option){
            let app = this;
            app.filterData.location = option.slug;
        },
        removedSelectedLocation(){
            let app = this;
            app.filterData.location = "";
        },
    },

}
</script>
