<template>
<div v-if="popUp">
  <b-modal ref="pop-up-modal2" id="modal-lg modal-multi-2 close" hide-footer hide-header centered visible>
    <div class="modal-close">
        <span class="closeModal" @click="hideModal('pop-up-modal2')">
            <span class="icon-close"></span>
        </span>
    </div>
    <div v-if="popUpLink !=null" class="modal-image-wrapper">
        <a :href="popUpLink" target="_blank"> <img :src="popUpImg"> </a>
    </div>
     <div v-else class="modal-image-wrapper">
        <img :src="popUpImg">
    </div>
  </b-modal>
</div>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
import Helper from "@/services/helper"
export default {
    name: 'homePopup',
    data(){
      return {
        popUp:false,
        popUpImg:"",
        popUpLink:"",
      }
    },
    mounted() {
      let app = this;
      app.renderPopUp();
    },
    methods: {
        hideModal(value) {
          if(value === 'pop-up-modal1'){
            this.$refs['pop-up-modal1'].hide()
          }else{
            this.$refs['pop-up-modal2'].hide()
          }
      },
      async renderPopUp(){
          let app = this;
          Helper.showSpinner();
          await axios.get(api.getUrl('/general-ads/popup')).then(function (response) {
              if(response.data.success){
                  if(response.data.data.length > 0){
                    app.popUp = true;
                    app.popUpImg = response.data.data[0].image;
                    app.popUpLink = response.data.data[0].link;
                  }else{
                    app.popUp = false;
                  }
              }
          }).finally(() => {
              Helper.hideSpinner();
          });
      }
    }
}
</script>