<template>
    <video-background 
      :src="bannerVideo"
      :poster="bannerImage"
      style="max-height: 600px; height: 100vh;"
      overlay="linear-gradient(45deg, rgb(0 0 0 / 37%), rgb(0 0 0 / 38%))"
      class="banner-wrap"
      >
      <div class="container">
        <div class="row justify-content-center align-items-center filterHeight">
          <div class="col-xl-10">
            <h1>{{ bannerTitle }}</h1>
            <p>{{ banndrDesc }}</p>
            <MasterSearch />
          </div>
        </div>
      </div>
  </video-background>
     
</template>
<script>
import MasterSearch from '../../../components/MasterSearch.vue';

import axios from 'axios'
import api from '@/services/api'
export default {
    name: "Banner",
    components: { MasterSearch },
    data() {
      return {
        bannerTitle:"",
        banndrDesc:"",
        bannerVideo:"",
        bannerImage:"",
      }
    },
    mounted() {
        let app = this;
        app.renderBannerInfo();
    },
    methods: {
        async renderBannerInfo(){
            let app = this;
            await axios.get(api.getUrl('/banner-info')).then(function (response) {
                if(response.data.success){
                    app.bannerTitle = response.data.data.banner_title;
                    app.banndrDesc  = response.data.data.banner_description;
                    app.bannerVideo = response.data.data.banner_video;
                    app.bannerImage = response.data.data.banner_image;
                }
            });
        }
    }
}
</script>