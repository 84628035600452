<template>
    <div v-if="allImages.length > 0" class="property-design mini-slider">
        <VueSlickCarousel :options="carouselOptions" @swiped="handleSwipe" @afterChange="handleAfterChange" ref="carousel" class="vue-carousel-img">
            <div v-for="(image, index) in allImages" :key="index">
                <img :src="image.image_path" class="vue-img-main" draggable="false"/>
            </div>
        </VueSlickCarousel>
    </div>
</template>
<style>
    .mini-slider .vue-carousel-img img{
        height: 205px !important;
    }
    .mini-slider .slick-slider .slick-next, .mini-slider .slick-slider .slick-prev{
        height: 25px;
        width: 25px;
    }
    .mini-slider .slick-slider .slick-next.slick-arrow::before, .mini-slider .slick-slider .slick-prev.slick-arrow::before {
        font-size: 20px;
    }
</style>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
    name: "MiniImgSlider",
    components: { VueSlickCarousel },
    props:{
        images:{
            required: true,
        }
    },
    data() {
        return {
            allImages: [],
            carouselOptions: {
                slidesToShow: 1,
                dots: false,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 5000,
                draggable: true,
                swipeToSlide: true,
                touchThreshold: 10,
            },
            activeImage: 0,
        };
    },
    mounted(){
        this.allImages = this.images;
    },
    methods: {
        handleSwipe(event) {
            const direction = event.direction === "left" ? 1 : -1;
            this.activeImage += direction;
        },
        handleAfterChange(index) {
            this.activeImage = index;
        },
    },
    watch: {
        images(value) {
            let app = this;
            app.allImages = value;
        },
    },
};
</script>