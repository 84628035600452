<template>
    <div>
        <homePopup />
        <Banner />
        <mobileBanner />
        <PremiumListing />
        <CategoryBlock />
        <LatestProperties />
        <BaseInfo />
        <ByLocation />
        <DirectAction />
    </div>
</template>
<script>
import homePopup from './partials/homePopup.vue';
import Banner from './partials/Banner.vue';
import mobileBanner from './partials/mobileBanner.vue';
import CategoryBlock from './partials/CategoryBlock.vue';
import LatestProperties from './partials/LatestProperties.vue';
import PremiumListing from './partials/PremiumListing.vue';
import BaseInfo from './partials/BaseInfo.vue';
import ByLocation from './partials/ByLocation.vue';
import DirectAction from '../../components/DirectAction.vue';
export default {
    name: 'Home',    
    components: {
    homePopup,
    Banner,
    mobileBanner,
    CategoryBlock,
    PremiumListing,
    LatestProperties,
    BaseInfo,
    ByLocation,
    DirectAction
},
}
</script>
<style src="../../assets/css/home.css"></style>