<template>
    <section class="latestWrap">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="page-title mb-4">
                        <div>
                            <h2>Latest Properties</h2>
                            <p>Aliquam lacinia diam quis lacus euismod</p>  
                        </div>
                        <div>
                            <ul class="custom-tab">
                                <li v-if="filterData.purpose=='sale'" class="active-tab" @click="filter('sale')">For Buy</li>
                                <li v-else @click="filter('sale')">For Buy</li>

                                <li v-if="filterData.purpose=='rent'" class="active-tab" @click="filter('rent')">For Rent</li>
                                <li v-else @click="filter('rent')">For Rent</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-6 col-6 mt-4" v-for="(item,i) in properties.data" :key="i">
                    <ItemList :item="item" />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="mt-4 text-end">
                        <router-link :to="{ name: 'PropertyListing'}" class="univ-btn px-4">See All Properties <i class="fas fa-arrow-right"></i></router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
import Helper from "@/services/helper"
import ItemList from '@/components/ItemList'
export default {
    name: 'LatestProperties',
    components: { 
        ItemList
    },
    data() {
        return {
            properties: { 
                data: []
            },
            filterData:{
                purpose:"sale",
                limit:8,
                sort:this.$route.query.sort?this.$route.query.sort:'latest',
            },
        };        
    },
    mounted() {
        let app = this;
        app.renderProperty(app.filterData);
    },
    methods: {
        async renderProperty(filters =[]){
            let app = this;
            let url;
            if (filters) {
                let filterUrl = Helper.objToUrlParams(filters);
                url = `/properties?${filterUrl}`;
            } else {
                url = `/properties`;
            }
            await axios.get(api.getUrl(url)).then(function (response) {
                if(response.data.success){
                    let responseData        = response.data.data;
                    app.properties.data     = responseData.data;
                }
            });
        },
        filter($value) {
            let app = this;
            app.filterData.purpose = $value;
            app.renderProperty(this.filterData);
        },
    }
}
</script>